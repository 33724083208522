import "./Header_bar.css";
import { useState } from 'react';
import { createPortal } from 'react-dom';
import img_logo from "../img/logo.gif";
import img_scan from "../img/qr-code.png";

import ModalContent from './ModalContent.js';

export default function Header_bar() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="Header_bar">
      <header className="H-start">
        <div className="H-start-logo">
          <img src={img_logo} alt="homes" className="H-start-logo-img"></img>
          <img
            src={img_scan}
            alt="scan"
            className="H-start-logo-icon-scan"
            onClick={() => setShowModal(true)}
          ></img>
          
          {showModal &&
            createPortal(
              <ModalContent onClose={() => setShowModal(false)} />,
              document.body
            )}
        </div>

        <div className="H-end">
          <div className="H-end-top">-Username-</div>
          <div className="H-end-buttom">
            <button className="btn-h">Menagement</button>
            <button className="btn-h">Display</button>
            <button className="btn-h">Setting</button>
          </div>
        </div>
      </header>
    </div>
  );
}
