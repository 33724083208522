import './ModalContent.css';
import { Scanner } from '@yudiel/react-qr-scanner';

export default function ModalContent({ onClose }) {
    return (
      <div className="modal">
        <div className='scan'>
        <Scanner onScan={(result) => console.log(result)} />
        </div>
        <button onClick={onClose}>Close</button>
        
      </div>
    );
  }
  