import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header_bar from './component/Header_bar';
import D_summary from './component/D_summary';
import reportWebVitals from './reportWebVitals';
// import { Scanner } from '@yudiel/react-qr-scanner';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header_bar/>
    <D_summary />
     {/* <Scanner classNames='A' onScan={(result) => console.log(result)} />  */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
